import { AppError, addErrorHandler, getAppStatus, registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';

import { onLoad as initPartners } from '@soluto-private/mx-partners';
import { monitor } from '@soluto-private/mx-monitor';
import { listen, Key, storageEvents } from '@soluto-private/mx-context';

import { initLayout, getLayout, getLayoutProps, Layout } from './utils/layout';
import { initialize as initializeAnalytics } from './analytics';
import { initialize as initializeContext } from './context';
import { parseCurrentRoute } from './utils/route';
import { markPerformance } from './utils/performance';
import { resetImportMap, getResetOverrides } from './utils/reset';
import { redirectToDowntimePage } from './utils/downtime';

const removeFcpLoader = () => document.getElementById('fcp-loader')?.remove();

const rewriteRetailToAsurion = () => {
  const path = window.location.pathname;
  const search = new URLSearchParams(window.location.search);
  if (search.get(Key.Client) === 'retail') {
    search.set(Key.Client, 'asurion');
    search.set(Key.ProductType, 'default');
    window.history.pushState(null, '', `${path}?${search}`);
  }
};

const monitorLifecycleError = (error: AppError) => {
  const status = getAppStatus(error.appOrParcelName);
  monitor.error('MyAsurion lifecycle failure', error, { mx: error.appOrParcelName, status });
};

const clearImportMapOverrides = () => {
  const isDevelopment = process.env.RELEASE_STAGE === 'development';
  const resetOverrides = getResetOverrides(window.location.href);

  if (isDevelopment && resetOverrides) {
    resetImportMap(window.location.href);
  }
};

const performDowntimeRedirect = () => {
  const isDevelopment = process.env.RELEASE_STAGE === 'development';
  const timeout = isDevelopment ? 10000 : 1000;
  window.setTimeout(redirectToDowntimePage, timeout);
};

addErrorHandler(monitorLifecycleError);
addErrorHandler(clearImportMapOverrides);
addErrorHandler(performDowntimeRedirect);

let applications;
const render = (layout: Layout) => {
  const routes = constructRoutes(layout);
  markPerformance('routes were constructed');

  markPerformance('constructApplications');

  applications = constructApplications({
    routes,
    loadApp: ({ name }) => System.import(name),
  });
  markPerformance('applications were constructed');
  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.every((application) => {
    let applicationRegistered = true;

    try {
      registerApplication({
        ...application,
        customProps(_appName, location) {
          return {
            routePrefix: parseCurrentRoute(location.pathname),
            ...getLayoutProps(_appName, location.pathname),
          };
        },
      });
    } catch (e) {
      applicationRegistered = false;
      monitor.error('Unable to register application', e);
      redirectToDowntimePage();
    }

    return applicationRegistered;
  });

  layoutEngine.activate();
  removeFcpLoader();
  start();

  markPerformance('single-spa started');
};

const handleClientUpdate = () => {
  initLayout().then(() => {
    markPerformance('single-spa constructLayoutEngine to update routes');
    const layout = getLayout();
    const routes = constructRoutes(layout);
    const layoutEngine = constructLayoutEngine({ routes, applications });
    layoutEngine.activate();
  });
};

export const initializeClientListener = () => {
  listen(storageEvents.keyUpdated(Key.Client), handleClientUpdate);
};

markPerformance('loading');

const initPlatform = async () => {
  rewriteRetailToAsurion();
  await initializeAnalytics();
  await initPartners();
  await initLayout();

  initializeClientListener();
  initializeContext();

  markPerformance('root-layout loaded');
  render(getLayout());
  markPerformance('rendered');
};

initPlatform();

window.addEventListener('single-spa:first-mount', () => {
  markPerformance('single-spa first-mount');
});

window.addEventListener('single-spa:before-first-mount', () => {
  markPerformance('single-spa before-first-mount');
});
