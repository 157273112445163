import dispatcher from '..';
import { Status } from '.';

const extractName = (mx: string): string => {
  return mx.indexOf('/') !== -1 ? mx.split('/')[1] : mx;
};

export const dispatchMxView = ({ detail }: CustomEvent): void => {
  detail.appsByNewStatus.MOUNTED.forEach((packageName) => {
    dispatcher.dispatch('OneService_Mx_View', {
      ExtraData: {
        mx: packageName,
      },
    });
  });
};

export const dispatchMxTimeToLoad = (mx: string, timeElapsed: number, status: Status) => {
  dispatcher.dispatch('OneService_Mx_Time_To_Load', {
    ExtraData: {
      mx: extractName(mx),
      timeToLoad: timeElapsed,
      type: status,
    },
  });
};
