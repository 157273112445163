import { EventName, addEventListener } from '@soluto-private/mx-app-authentication';
import { storage, Key } from '@soluto-private/mx-context';

const clearContext = () => {
  storage.remove(Key.Client);
  storage.remove(Key.Services);
  storage.remove(Key.Plans);
};

export const initialize = () => {
  addEventListener(EventName.LoggedOut, clearContext);
};
