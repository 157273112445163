import { updateIdentity } from '@soluto-private/mx-analytics';
import { addEventListener, EventName, User } from '@soluto-private/mx-app-authentication';

const updateAsurionIdentity = (user?: User) => {
  const asurionIdentity = user?.profile?.asurion_id ?? '';

  updateIdentity('AsurionId', asurionIdentity);
};

export const attachAsurionIdentity = async (): Promise<void> => {
  addEventListener(EventName.LoggedIn, ({ user }) => updateAsurionIdentity(user));
  addEventListener(EventName.UserChanged, ({ user }) => updateAsurionIdentity(user));
  addEventListener(EventName.LoggedOut, () => updateAsurionIdentity());
  addEventListener(EventName.TokenRefreshed, ({ user }) => updateAsurionIdentity(user));
};
