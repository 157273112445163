import {
  addEventListener,
  AuthenticationState,
  EventName,
  getAuthenticationState,
} from '@soluto-private/mx-app-authentication';
import { getUserMetadata } from './userMetadata';

type Metadata = {
  [prop: string]: unknown;
};

declare global {
  interface Window {
    Bugsnag: {
      setUser(id: string, email?: string, name?: string): void;
      addMetadata(prop: string, data: Metadata): void;
      clearMetadata(prop: string): void;
    };
  }
}

const updateBugsnagMetadata = async (): Promise<void> => {
  const { id, plans, services } = await getUserMetadata();

  if (id) {
    window.Bugsnag.setUser(id);
    window.Bugsnag.addMetadata('user', { plans, services });
  }
};

export const manageBugsnagUser = async (): Promise<void> => {
  if (getAuthenticationState() === AuthenticationState.LoggedIn) {
    await updateBugsnagMetadata();
  }

  addEventListener(EventName.LoggedIn, updateBugsnagMetadata);
  addEventListener(EventName.UserChanged, updateBugsnagMetadata);
  addEventListener(EventName.LoggedOut, () => window.Bugsnag.clearMetadata('user'));
};
