import { createProvider, Provider } from '@soluto-private/mx-config';
import { PartnerProductConfiguration } from '@soluto-private/mx-types';
import type { Partner, ProductType } from '@soluto-private/mx-types';
import { monitor } from '@soluto-private/mx-monitor';

const defaultPartnerProductConfiguration: Partial<PartnerProductConfiguration> = {
  accountNav: { enabled: false },
  i18n: { defaultLocale: 'en-US', supportedLocales: ['en-US'] },
  leftNav: { enabled: false },
};

export const getPartnerProductConfiguration = async (
  partner: Partner,
  productType?: ProductType,
): Promise<Partial<PartnerProductConfiguration>> => {
  const provider = createProvider(Provider.OneServiceApi);

  try {
    return await provider.getPartnerProductConfiguration(partner, productType);
  } catch (error) {
    monitor.error('Error fetching Partner-Product configuration', error, { partner, productType });
  }

  return defaultPartnerProductConfiguration;
};
