let shouldPerformDowntimeRedirect = true;

// Don't redirect to the downtime page if we're navigating away
window.addEventListener('beforeunload', () => {
  shouldPerformDowntimeRedirect = false;
});

export const redirectToDowntimePage = () => {
  if (shouldPerformDowntimeRedirect) {
    const processErrorPageUrl = process.env.GENERIC_ERROR_URL;
    window.location.href = processErrorPageUrl;
  }
};
