import * as singleSpa from 'single-spa';
import { AppStatus, Status, dispatchMxTimeToLoad } from '.';

const MxStatusMap = new Map<string, AppStatus>();

const isStatus = (status: string): boolean => {
  return Object.values(Status).includes(status as Status);
};

export const getMxStatusMap = () => {
  return MxStatusMap;
};

export const populateMxStatusMap = () => {
  const mxList = singleSpa.getAppNames();
  mxList.forEach((mx) => {
    MxStatusMap.set(mx, { currentStatus: Status.Unmount, timeStart: performance.now() });
  });
};

export const beforeStatusChange = ({ detail }: CustomEvent): void => {
  Object.entries(detail.newAppStatuses).forEach(([mx, newStatus]: [string, string]) => {
    const mxAppStatus = MxStatusMap.get(mx);
    if (isStatus(newStatus) && mxAppStatus?.currentStatus !== newStatus) {
      MxStatusMap.set(mx, { ...mxAppStatus, timeStart: performance.now() });
    }
  });
};

export const afterStatusChange = ({ detail }: CustomEvent): void => {
  Object.entries(detail.newAppStatuses).forEach(([mx, newStatus]: [string, string]) => {
    if (isStatus(newStatus) && singleSpa.getAppStatus(mx) === newStatus) {
      const mxAppStatus = MxStatusMap.get(mx);
      const timeEnd = performance.now();
      const timeElapsed = (timeEnd - mxAppStatus.timeStart) / 1000;
      dispatchMxTimeToLoad(mx, timeElapsed, newStatus as Status);
      MxStatusMap.set(mx, { currentStatus: newStatus as Status, timeStart: 0 });
    }
  });
};
