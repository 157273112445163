import { getUser } from '@soluto-private/mx-app-authentication';
import { storage, Key } from '@soluto-private/mx-context';

export const getUserMetadata = async () => {
  const user = await getUser();
  const plans = storage.get(Key.Plans);
  const services = storage.get(Key.Services);
  return {
    id: user?.profile?.asurion_id,
    plans,
    services,
  };
};
