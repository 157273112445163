const ROUTE_PATTERN = /^(?<prefix>\/[^/]+)(?<tail>.*)?/;

export type ParsedRoute = {
  prefix: string;
  tail?: string;
};

export const parseCurrentRoute = (path: string): ParsedRoute | unknown => {
  if (path === '/') return {};
  if (path.match(ROUTE_PATTERN)) return path.match(ROUTE_PATTERN).groups;
  throw new Error('Path does not match defined pattern, could not parse it.');
};
