import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB, Metric } from 'web-vitals';
import { monitor } from '@soluto-private/mx-monitor';
import { UserBrowser } from '@soluto-private/mx-user-browser';

const analytics = Analytics({
  app: 'oneservice-mx-platform',
  plugins: [
    googleTagManager({
      containerId: process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID,
      preview: process.env.GOOGLE_TAG_MANAGER_ENV,
      auth: process.env.GOOGLE_TAG_MANAGER_AUTH_CODE,
    }),
  ],
});

export const sendWebVitalsToGTM = ({ name, id, delta, value }: Metric) =>
  analytics.track('coreWebVitals', {
    webVitalsMeasurement: {
      name,
      id,
      value,
      delta,
      valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
      deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
    },
  });

const sendWebVitalsToMonitor = ({ name, id, delta, value }: Metric) =>
  monitor.info('core web vital', {
    name,
    id,
    delta,
    value,
    valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
    deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
    device: UserBrowser.UserAgent.DeviceFormFactor,
    browser: UserBrowser.UserAgent.Browser,
  });

const trackingFunctions = [sendWebVitalsToGTM];
const track = (metric: Metric) => trackingFunctions.map((t) => t(metric));

const metricFunctions = [onCLS, onFCP, onFID, onINP, onLCP, onTTFB];
export const startWebVitalsAnalytics = () => {
  metricFunctions.map((m) => m(track));
};
