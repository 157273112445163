// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import defaultLayout from '../../public/assets/layout.json';
import { storage, Key } from '@soluto-private/mx-context';
import type { Partner } from '@soluto-private/mx-types';
import { getPartnerProductConfiguration } from './configuration';
import { PartnerProductConfiguration } from '@soluto-private/mx-types';

export type CustomAttribute = {
  name: string;
  value: string;
};

export type Route = {
  type: 'route' | 'application' | 'div';
  routes?: Route[];
  path?: string;
  name?: string;
  props?: Record<string, unknown>;
  attrs?: CustomAttribute[];
};

export type Layout = {
  routes: Route[];
  containerEl: string;
};

const layoutCacheKey = 'mx-layout';

export const cacheLayout = (layout: Layout): void => sessionStorage.setItem(layoutCacheKey, JSON.stringify(layout));

const getCachedLayout = (): Layout => {
  return JSON.parse(sessionStorage.getItem(layoutCacheKey));
};

const getDefaultLayout = (): Layout => {
  const layout = JSON.parse(JSON.stringify(defaultLayout));
  return layout as unknown as Layout;
};

export let getLayout = getDefaultLayout;

const applyConfiguration = (
  partnerProductConfiguration: () => Promise<Partial<PartnerProductConfiguration>>,
  { props, type, routes, ...rest }: Route,
): Route => ({
  type,
  ...rest,
  ...(type !== 'div' ? { props: { partnerProductConfiguration, ...props } } : {}),
  ...(routes ? { routes: routes.map((r) => applyConfiguration(partnerProductConfiguration, r)) } : {}),
});

const partnerProductConfiguration = async (): Promise<Partial<PartnerProductConfiguration>> => {
  const partner = storage.get(Key.Client) as Partner;
  const productType = storage.get(Key.ProductType);
  return await getPartnerProductConfiguration(partner, productType);
};

const applyTransformations = async (layout: Layout) => {
  layout.routes = layout.routes.map((r) => applyConfiguration(partnerProductConfiguration, r));
  return layout;
};

export const initLayout = async () => {
  if (process.env.RELEASE_STAGE !== 'production') {
    if (!sessionStorage.getItem(layoutCacheKey)) {
      cacheLayout(getDefaultLayout());
    }
    getLayout = getCachedLayout;
  } else {
    getLayout = getDefaultLayout;
  }

  const layout = getLayout();
  const transformedLayout = await applyTransformations(layout);
  getLayout = () => transformedLayout;
};

const getAppProps = (route: Route, appName: string): Record<string, unknown> => {
  const app = route.routes.find((r) => r.name === appName);
  return app ? app.props || {} : {};
};

export const getLayoutProps = (appName: string, path: string): Record<string, unknown> => {
  const route = getLayout().routes.find((r) => path.startsWith(r.path));
  return route
    ? {
        ...(route.props || {}),
        ...getAppProps(route, appName),
      }
    : {};
};
