export function getResetOverrides(route: string): boolean {
  const newURL = new URL(route);
  const resetOverridesFound = !!newURL.searchParams.get('resetOverrides');
  return resetOverridesFound;
}

export function resetImportMap(route: string): void {
  const resetOverrides: boolean = getResetOverrides(route);
  if (resetOverrides) {
    for (const key in window.localStorage) {
      if (key.startsWith('import-map-override')) {
        localStorage.removeItem(key);
      }
    }
  }
}

resetImportMap(window.location.href);
