import {
  addEventListener,
  AuthenticationState,
  EventName,
  getAuthenticationState,
} from '@soluto-private/mx-app-authentication';
import { getUserMetadata } from './userMetadata';

declare global {
  interface Window {
    FS: {
      identify(uid: string, customVars?: unknown): void;
      shutdown(): void;
    };
  }
}

const updateFullstoryMetadata = async (): Promise<void> => {
  const { id, plans, services } = await getUserMetadata();

  if (id) {
    window.FS.identify(id, { plans, services });
  }
};

export const manageFullStory = async (): Promise<void> => {
  if (getAuthenticationState() === AuthenticationState.LoggedIn) {
    await updateFullstoryMetadata();
  }

  addEventListener(EventName.LoggedIn, updateFullstoryMetadata);
  addEventListener(EventName.UserChanged, updateFullstoryMetadata);
  addEventListener(EventName.LoggedOut, () => window.FS.shutdown());
};
