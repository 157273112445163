import { addEventListener, EventName } from '@soluto-private/mx-app-authentication';
import dispatcher from '..';

export const dispatchUserLogin = ({ user }): void => {
  dispatcher.dispatch('OneService_Authentication_LoggedIn', {
    ExtraData: {
      user: {
        asurion_id: user.profile.asurion_id,
      },
    },
  });
};

export const dispatchUserChanged = ({ user, prevUser }): void => {
  dispatcher.dispatch('OneService_Authentication_UserChanged', {
    ExtraData: {
      user: {
        asurion_id: user.profile.asurion_id,
      },
      prevUser: {
        asurion_id: prevUser.profile.asurion_id,
      },
    },
  });
};

export const dispatchTokenRefreshed = ({ user }): void => {
  dispatcher.dispatch('OneService_Authentication_TokenRefreshed', {
    ExtraData: {
      user: {
        asurion_id: user.profile.asurion_id,
      },
    },
  });
};

export const dispatchUserLoggedOut = ({ prevUser }): void => {
  dispatcher.dispatch('OneService_Authentication_LoggedOut', {
    ExtraData: {
      prevUser: {
        asurion_id: prevUser.profile.asurion_id,
      },
    },
  });
};

export const dispatchAuthenticationError = ({ method, error }): void => {
  dispatcher.dispatch('OneService_Authentication_Error', {
    ExtraData: {
      method,
      error,
    },
  });
};

export const listenToAuthEvents = (): void => {
  addEventListener(EventName.LoggedIn, dispatchUserLogin);
  addEventListener(EventName.UserChanged, dispatchUserChanged);
  addEventListener(EventName.Error, dispatchAuthenticationError);
  addEventListener(EventName.LoggedOut, dispatchUserLoggedOut);
  addEventListener(EventName.TokenRefreshed, dispatchTokenRefreshed);
};
